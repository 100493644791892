import QRCode from "qrcode.react";
import {
  Button,
  InputLabelWrapper,
  PageLayout,
  QuickForm,
  QuickFormInput
} from "../../components";
import { Field } from "formik";
import { PaymentDetails } from "../../types";

const CRYPTOS = {
  BTC: "btc",
  USDC: "usdc",
  ETH: "eth"
};

type Props = {
  submitInvoice: (data: any) => void;
  details: PaymentDetails | null;
  error?: string;
  clearData: () => void;
  selectedCrypto: string;
  setSelectedCrypto: (crypto: string) => void;
};

const Invoice_View: React.FC<Props> = ({
  submitInvoice,
  details,
  error,
  clearData,
  selectedCrypto,
  setSelectedCrypto
}) => {
  const fiat =
    "$" +
    details?.fiat.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) +
    " AUD";

  const btcButton = () => {
    return (
      <Button
        id="selectedCryptoBtc"
        onClick={() => setSelectedCrypto(CRYPTOS.BTC)}
        className={
          selectedCrypto === CRYPTOS.BTC
            ? "md:w-1/4 py-2 mb-2 ring-2 ring-offset-2 ring-indigo-500 bg-blue-200"
            : "md:w-1/4 py-2 mb-2"
        }
      >
        <span className="flex flex-wrap p-1">BTC</span>
      </Button>
    );
  };

  const usdcButton = () => {
    return (
      <Button
        id="selectedCryptoUsdc"
        onClick={() => setSelectedCrypto(CRYPTOS.USDC)}
        className={
          selectedCrypto === CRYPTOS.USDC
            ? "md:w-1/4 py-2 mb-2 ring-2 ring-offset-2 ring-indigo-500 bg-blue-200"
            : "md:w-1/4 py-2 mb-2"
        }
      >
        <span className="flex flex-wrap p-1">USDC</span>
      </Button>
    );
  };

  const ethButton = () => {
    return (
      <Button
        id="selectedCryptoEth"
        onClick={() => setSelectedCrypto(CRYPTOS.ETH)}
        className={
          selectedCrypto === CRYPTOS.ETH
            ? "md:w-1/4 py-2 mb-2 ring-2 ring-offset-2 ring-indigo-500 bg-blue-200"
            : "md:w-1/4 py-2 mb-2"
        }
      >
        <span className="flex flex-wrap p-1">ETH</span>
      </Button>
    );
  };

  return (
    <PageLayout>
      {!details && (
        <div className="mb-6 pl-40">
          <QuickForm
            initialValues={{ invoice: "", coin: "btc" }}
            validator={() => ({})}
            onSubmit={submitInvoice}
            error={error}
            buttonTitle="View Your Invoice"
            disabled={false}
          >
            <div className="my-6 space-y-6">
              <InputLabelWrapper label="Invoice ID" htmlFor="invoice">
                <QuickFormInput
                  id="id"
                  name="id"
                  type="text"
                  placeholder="Enter your Invoice ID"
                  className="text-md border-none focus:ring-black focus:border-black lg:text-xl m-0"
                />
              </InputLabelWrapper>
            </div>
            <InputLabelWrapper label="Selected Crypto" htmlFor="coin">
              <div className="flex flex-wrap justify-between md:mr-52 my-2">
                <Field name="Selected Crypto BTC" component={btcButton} />
                <Field name="Selected Crypto ETH" component={ethButton} />
                <Field name="Selected Crypto USDC" component={usdcButton} />
              </div>
            </InputLabelWrapper>
          </QuickForm>
        </div>
      )}
      {details && (
        <>
          <div className="flex flex-col space-y-6 mx-auto p-2 my-8 text-white">
            <div className="flex justify-center my-4">
              <QRCode value={details.qr_url} />
            </div>
            <div className="flex justify-between">
              <div className="text-lg font-bold">Invoice ID</div>

              <div>
                {
                  // TODO: Work out why there is no id in the details object
                  details.id
                }
              </div>
            </div>
            <div className="flex justify-between">
              <div>Amount</div>
              <div>
                {details.amount.toFixed(6)} {details.coin.toUpperCase()}
              </div>
            </div>
            <div className="flex justify-between">
              <div>Chain</div>
              <div>{details.chain}</div>
            </div>
            <div className="flex justify-between">
              <div>Payment Address</div>
              <div className="truncate">{details.address}</div>
            </div>
            <div className="flex justify-between">
              <div>AUD Amount</div>
              <div>{fiat}</div>
            </div>
            <div className="w-3/5 mx-auto flex justify-center">
              <Button className="mt-8" onClick={clearData}>
                Go Back
              </Button>
            </div>
          </div>
          <br></br>
          {/* {details.coin !== "BTC" && (
            <div className="w-3/5 mx-auto mb-5">
              <Button>Bitcoin</Button>
            </div>
          )} */}
        </>
      )}
    </PageLayout>
  );
};

export default Invoice_View;
