import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
// import Loader from "./components/Loader/Loader_View";
// import NotFound from "./pages/NotFound/NotFound_View";
import Invoice from "./pages/Invoice/Invoice_Logic";

export const history = createBrowserHistory();

// const CatchAllRoute = ({ component, ...props }: any) => {
//   const Component = component;
//   return (
//     <Route
//       {...props}
//       render={() => {
//         if (loading) return <Loader />;
//         return user ? <Component /> : <Redirect to="/account" />;
//       }}
//     />
//   );
// };

const Navigation = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={Invoice} exact />
      </Switch>
    </Router>
  );
};

export default Navigation;
