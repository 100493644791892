// import { Fragment } from "react";
// import classnames from "classnames";
// import { Link } from "react-router-dom";
// import { Loader } from "..";

// import {
//   BellIcon,
//   MenuIcon,
//   XIcon,
//   UserCircleIcon
// } from "@heroicons/react/outline";

// const navigation = [
//   { name: "Dashboard", path: "/dashboard" },
//   { name: "Transactions", path: "/transactions" },
//   { name: "Support", path: "/support" }
// ];

// const userNavigation = [
//   { name: "Your Profile", path: "/profile" },
//   { name: "Settings", path: "/settings" },
//   { name: "Sign out", path: "#" }
// ];

type Props = {
  loading: boolean;
  currentPath: string;
  // user: api.User | undefined;
};

const PageLayoutView: React.FC<Props> = props => {
  return (
    <div className="min-h-screen bg-black">
      <div className="py-10">
        <img
          src="/images/dltx-white.png"
          alt=""
          className="w-full justify-center object-scale-down h-48 shadow-blue shadow-blue-500/40"
        />
        <main>
          <div className="max-w-3xl mx-auto">{props.children}</div>
        </main>
      </div>
    </div>
  );
};

export default PageLayoutView;
