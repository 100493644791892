import InvoiceView from "./Invoice_View";
import * as React from "react";
import useApi from "../../hooks/useApi";
import { InvoiceRequest, PaymentDetails } from "../../types";

type Props = {};

const Invoice: React.FC<Props> = () => {
  const api = useApi();

  const [data, setData] = React.useState<PaymentDetails | null>(null);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [selectedCrypto, setSelectedCrypto] = React.useState<string>("btc");

  const submitInvoice = async (request: InvoiceRequest) => {
    const { id } = request;
    setError(undefined);
    setData(null);

    try {
      const paymentDetails = await api.fetchInvoice(id, selectedCrypto);
      // TODO: FIX COIN URL
      paymentDetails.qr_url = `${paymentDetails?.coin}:${paymentDetails?.address}?amount=${paymentDetails?.amount}&label=${paymentDetails?.id}`;
      setData(paymentDetails);
    } catch (error) {
      console.error(error);
      setError("There was an error fetching the invoice");
    }
  };

  return (
    <InvoiceView
      submitInvoice={submitInvoice}
      details={data}
      error={error}
      clearData={() => setData(null)}
      selectedCrypto={selectedCrypto}
      setSelectedCrypto={setSelectedCrypto}
    />
  );
};

export default Invoice;
