import { PaymentDetails } from "../types";
import HTTPClient from "./HTTPClient";

export default class Api extends HTTPClient {
  // TODO: MAYBE REMOVE
  public fetchInvoice = async (id: string, coin: string) =>
    this.get<PaymentDetails>(
      `https://payments.dltx.io/api/invoice/${id}?coin=${coin}`
    );

  public createInvoice = async (id: string, coin: string) =>
    this.post<PaymentDetails>(
      `https://payments.dltx.io/api/invoice/${id}?coin=${coin}`
    );
}
